import React from 'react'

import ForcesHero, { ForceHero, SimpleHero } from '../Heroes'
import FeaturedProduct from '../Featured'
import BackgroundImageWithButton from '../BackgroundImageWithButton'
import ProductsCarousel from '../Carousels'
import ContactForm from '../ContactForm'
import TextEditor from '../TextEditor'
import Licensees from '../Licensees'

const getSliceType = ({ key, type, props }) => {
    switch (type) {
        case 'forces_hero':
            return (
                <ForcesHero
                    {...{
                        key,
                        ...props,
                    }}
                />
            )
        case 'force_hero':
            return <ForceHero {...{ key, ...props }} />
        case 'simple_hero':
            return <SimpleHero {...{ key, ...props }} />
        case 'featured_product':
            return <FeaturedProduct {...{ key, ...props }} />
        case 'background_image_with_button':
            return <BackgroundImageWithButton {...{ key, ...props }} />
        case 'carousel_products':
            return <ProductsCarousel {...{ key, ...props }} />
        case 'contact_form':
            return <ContactForm {...{ key, ...props }} />
        case 'text_editor':
            return <TextEditor {...{ key, ...props }} />
        case 'licensees':
            return <Licensees {...{ key, ...props }} />
        default:
            return <span />
    }
}
const Slices = ({ slices = [], location, config, licensees, accent_colour }) =>
    slices.map(({ slice_type: type, ...props }, i) =>
        getSliceType({
            key: i,
            type,
            props: { location, ...config, accent_colour, licensees, ...props },
        })
    )

export default Slices
