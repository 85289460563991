import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

import Link from '../../Link'
import Background from '../../Background'

const SimpleHero = ({ location, primary: _primary, simpleHeroPrimary }) => {
    const primary = simpleHeroPrimary || _primary

    const { data } = useMergePrismicPreviewData(
        useStaticQuery(graphql`
            query getSimpleHero {
                config: prismicGlobalConfiguration {
                    _previewable
                    data {
                        share_image {
                            fluid {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                                srcSetWebp
                                srcWebp
                            }
                        }
                        main_menu {
                            label
                            link {
                                uid
                                url
                            }
                            colour
                        }
                    }
                }
            }
        `)
    )

    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        setLoaded(true)
    }, [])
    const items = data?.config?.data?.main_menu || []

    return (
        <>
            {items?.length > 0 ? (
                <div className="flex bg-navy">
                    <span className="flex-0 block w-full md:w-1/2 lg:w-96 ml-5" />
                    <ul className="flex-1 hidden xl:flex items-center justify-start w-full z-10 bg-navy font-bold text-sm text-white uppercase">
                        {items?.map(({ link, label, colour }) => (
                            <li className="flex-1 w-full xl:w-auto">
                                <Link
                                    to={
                                        link?.uid
                                            ? `/${
                                                  link?.uid === 'home'
                                                      ? ''
                                                      : link?.uid
                                              }`
                                            : link?.url
                                    }
                                    external={!link?.uid}
                                    className="group flex items-center justify-center w-full xl:w-auto h-20 px-8 relative overflow-hidden"
                                >
                                    <span
                                        className={`${
                                            colour?.toLowerCase() === '#ffffff'
                                                ? 'group-hover:text-navy'
                                                : ''
                                        } block transform relative z-10 transition-all whitespace-nowrap`}
                                    >
                                        {label}
                                    </span>
                                    <span
                                        className={`block w-full h-full absolute left-0 transform ${
                                            location?.pathname ===
                                            `/${link?.uid}`
                                                ? 'top-0 -translate-y-0'
                                                : '-top-2 translate-y-full group-hover:top-0 group-hover:-translate-y-0'
                                        } bg-grey z-0 transition-all`}
                                        style={{
                                            backgroundColor: colour || null,
                                        }}
                                    />
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}
            <div className="flex-1 flex flex-col md:flex-row-reverse w-full lg:h-screen-3/5 md:pl-5 relative bg-navy">
                <span className="flex-0 block w-full md:w-5 h-5 md:h-full absolute bottom-0 md:top-0 left-0 bg-grey" />

                <div className="flex-1 pt-9/16 pt-0 relative">
                    <div className="w-full h-full lg:h-screen-3/5 absolute top-0 left-0">
                        <Background
                            fluid={data?.config?.data?.share_image?.fluid}
                            className="w-full h-full absolute top-0 left-0 bg-cover bg-bottom z-0"
                            style={{ position: 'absolute!important' }}
                        />
                        <span className="block w-full h-full absolute top-0 left-0 bg-navy opacity-50" />
                    </div>
                </div>
                <div className="flex-0 flex flex-col w-full md:w-1/2 lg:w-96 h-full p-8 md:p-20 lg:p-12 mb-5 md:mb-0 bg-navy text-white text-center md:text-left">
                    {primary?.heading ? (
                        <h1
                            className={`md:my-8 lg:my-12 transform ${
                                loaded
                                    ? 'translate-x-0 opacity-100'
                                    : 'translate-x-8 opacity-0'
                            } font-semibold text-2xl md:text-3xl lg:text-4xl text-white z-20 transition-all duration-500`}
                        >
                            {primary?.heading}
                        </h1>
                    ) : null}
                    <p
                        className={`w-96 md:w-72 max-w-full mx-auto md:mx-0 transform ${
                            loaded
                                ? 'translate-y-0 opacity-100'
                                : 'translate-y-8 opacity-0'
                        } text-sm transition-all delay-200 duration-1000 text-sm`}
                    >
                        {primary?.description}
                    </p>
                </div>
            </div>
        </>
    )
}

export default SimpleHero
