import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { linkResolver } from '../../linkResolver'

import Root from '../components/Root'
import Meta from '../components/Meta'
import Slices from '../components/Slices'

export const query = graphql`
    query getPage($id: String) {
        page: prismicPage(id: { eq: $id }) {
            _previewable
            id
            data {
                body {
                    ... on PrismicPageDataBodyForcesHero {
                        slice_type
                        forcesHeroPrimary: primary {
                            heading
                            background_image {
                                fluid {
                                    aspectRatio
                                    base64
                                    sizes
                                    src
                                    srcSet
                                    srcSetWebp
                                    srcWebp
                                }
                            }
                            bottom_label
                            bottom_link {
                                uid
                            }
                            bottom_small_text
                        }
                        forcesHeroItems: items {
                            armed_force {
                                uid
                                document {
                                    ... on PrismicArmedForce {
                                        type
                                        _previewable
                                        data {
                                            name
                                            logo {
                                                gatsbyImageData
                                                localFile {
                                                    childImageSharp {
                                                        gatsbyImageData
                                                    }
                                                }
                                            }
                                            accent_colour
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicPageDataBodyForceHero {
                        id
                        slice_type
                        forceHeroPrimary: primary {
                            armed_force {
                                uid
                                document {
                                    ... on PrismicArmedForce {
                                        type
                                        _previewable
                                        data {
                                            name
                                            logo {
                                                gatsbyImageData
                                                localFile {
                                                    childImageSharp {
                                                        gatsbyImageData
                                                    }
                                                }
                                            }
                                            accent_colour
                                        }
                                    }
                                }
                            }
                            description
                            background_image {
                                fluid {
                                    aspectRatio
                                    base64
                                    sizes
                                    src
                                    srcSet
                                    srcSetWebp
                                    srcWebp
                                }
                            }
                        }
                    }
                    ... on PrismicPageDataBodyFeaturedProduct {
                        slice_type
                        featuredProductPrimary: primary {
                            background_text
                            subheading
                            link {
                                uid
                            }
                            description {
                                html
                            }
                            button_text
                            image_one {
                                localFile {
                                    publicURL
                                }
                                square: gatsbyImageData(width: 500, height: 500)
                                wide: gatsbyImageData(width: 750, height: 580)
                            }
                            image_two {
                                localFile {
                                    publicURL
                                }
                                square: gatsbyImageData(width: 500, height: 500)
                                wide: gatsbyImageData(width: 750, height: 580)
                            }
                            armed_force {
                                document {
                                    ... on PrismicArmedForce {
                                        _previewable
                                        data {
                                            accent_colour
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicPageDataBodyBackgroundImageWithButton {
                        slice_type
                        backgroundImageWithButtonPrimary: primary {
                            heading
                            link {
                                uid
                                url
                            }
                            button_label
                            background_image {
                                fluid {
                                    aspectRatio
                                    base64
                                    sizes
                                    src
                                    srcSet
                                    srcSetWebp
                                    srcWebp
                                }
                            }
                        }
                    }
                    ... on PrismicPageDataBodyCarouselProducts {
                        slice_type
                        carouselProductsPrimary: primary {
                            heading
                            space_top
                        }
                        carouselProductsItems: items {
                            subheading
                            heading
                            description
                            image_one {
                                localFile {
                                    publicURL
                                }
                                small: gatsbyImageData(width: 550, height: 360)
                                half: gatsbyImageData(width: 550, height: 720)
                                full: gatsbyImageData(width: 850, height: 553)
                            }
                            image_two {
                                localFile {
                                    publicURL
                                }
                                small: gatsbyImageData(width: 550, height: 360)
                                half: gatsbyImageData(width: 550, height: 720)
                                full: gatsbyImageData(width: 850, height: 553)
                            }
                            image_three {
                                localFile {
                                    publicURL
                                }
                                small: gatsbyImageData(width: 550, height: 360)
                                half: gatsbyImageData(width: 550, height: 720)
                                full: gatsbyImageData(width: 850, height: 553)
                            }
                            image_four {
                                localFile {
                                    publicURL
                                }
                                small: gatsbyImageData(width: 550, height: 360)
                                half: gatsbyImageData(width: 550, height: 720)
                                full: gatsbyImageData(width: 850, height: 553)
                            }
                            button_label
                            link {
                                uid
                                url
                            }
                        }
                    }
                    ... on PrismicPageDataBodySimpleHero {
                        slice_type
                        simpleHeroPrimary: primary {
                            heading
                            description
                            background_image {
                                fluid {
                                    aspectRatio
                                    base64
                                    sizes
                                    src
                                    srcSet
                                    srcSetWebp
                                    srcWebp
                                }
                            }
                        }
                    }
                    ... on PrismicPageDataBodyContactForm {
                        slice_type
                        contactFormPrimary: primary {
                            licensee_description {
                                html
                            }
                        }
                    }
                    ... on PrismicPageDataBodyTextEditor {
                        slice_type
                        textEditorPrimary: primary {
                            content {
                                html
                            }
                        }
                    }
                    ... on PrismicPageDataBodyLicensees {
                        slice_type
                        licenseePrimary: primary {
                            heading
                            description {
                                html
                            }
                        }
                    }
                }
                page_title
                meta_description
                share_image {
                    url
                }
                always_show_header
            }
        }
    }
`
const PageTemplate = ({
    location,
    data: {
        page: {
            data: { body: slices, ...pageData },
        },
    },
    pageContext: { config, licensees },
}) => {
    const meta = {
        title: pageData?.page_title,
        description: pageData?.meta_description,
        shareImage: pageData?.share_image,
    }

    return (
        <Root
            {...{ config, location, forceHeader: pageData?.always_show_header }}
        >
            <Meta {...{ config, meta }} />
            <Slices {...{ location, config, slices, licensees }} />
        </Root>
    )
}

export default withPrismicPreview(PageTemplate, [
    {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
    },
])
