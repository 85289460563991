import React, { useState } from 'react'

import interfaceValidationCheck from '../../images/global/interface-validation-check.svg'

import Spinner from '../Spinner'
import SVG from '../SVG'

const enquiryTypes = {
    LICENSEE: 'licensee',
    GENERAL: 'general',
}

const statuses = {
    PENDING: 'pending',
    INVALID: 'invalid',
    SUCCESS: 'success',
    LOADING: 'loading',
    ERROR: 'error',
}

const ContactForm = ({ contactFormPrimary: primary }) => {
    const [enquiryType, setFormType] = useState(enquiryTypes.LICENSEE)
    const [status, setStatus] = useState(statuses.PENDING)
    const [role, setRole] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companySize, setCompanySize] = useState('')
    const companySizes = [
        { value: '1', label: '1' },
        { value: '2-5', label: '2-5' },
        { value: '6-15', label: '6-15' },
        { value: '16-50', label: '16-50' },
        { value: '51-100', label: '51-100' },
        { value: '101-250', label: '101-250' },
        { value: '251-500', label: '251-500' },
        { value: '500+', label: '500+' },
    ]
    const [companyWebsite, setCompanyWebsite] = useState('')
    const [countryEstablished, setCountryEstablished] = useState('')
    const [companyTurnover, setCompanyTurnover] = useState('')
    const [licenseBrands, setLicenseBrands] = useState(true)
    const [licenseBrandExamples, setLicenseBrandExamples] = useState('')
    const [brandInterests, setBrandInterests] = useState('')
    const [productType, setProductType] = useState('')
    const [acceptedTerms, setAcceptedTerms] = useState(false)
    const disabled = [statuses.LOADING, statuses.SUCCESS].includes(status)

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (acceptedTerms && role === '') {
            setStatus(statuses.LOADING)
            let body = []

            if (enquiryType === enquiryTypes.GENERAL) {
                body = [
                    `_to=${encodeURIComponent(
                        'd64d6241de5ba227714ed83a07995a697d07d8572c69981a632ab261e2f58d49c296ed9fc352c912d543e75b522ddc2e898d7c0e49c0050ff9ef62cd9071740f12388d0e32d192d3e92be34d4d824a4f902df31d80d1e2e1843c4589b21e3dccf0e3e6dd2a806819ad69b5aacdb6969381d096dbfe919d6a296509355465973d214a'
                    )}`,
                    `_replyTo=${encodeURIComponent(email)}`,
                    `_honeypot=${encodeURIComponent(role)}`,

                    `email=${encodeURIComponent(email)}`,
                    `name=${encodeURIComponent(name)}`,
                    `subject=${encodeURIComponent(subject)}`,
                    `message=${encodeURIComponent(message)}`,
                ].join('&')
            } else {
                body = [
                    `_to=${encodeURIComponent(
                        '2cf8b0837283cb4e403707a1dc9d7d46ce74bcce7f05733442c4d67ac91a49d74b553be2b2fc9e4a15091a5ec2a6c1270c52dc1696e145b1bbdf32feb707fb8d3943e5c23ac30cce337f0e913f5de144d0d32923169893ad54f111e57427acef66852f32bc791cd49df8f9d66ff2d00fd9c46dc3fd044908e7435828f7d63d1a3f305c'
                    )}`,
                    `_replyTo=${encodeURIComponent(email)}`,
                    `_honeypot=${encodeURIComponent(role)}`,

                    `email=${encodeURIComponent(email)}`,
                    `name=${encodeURIComponent(name)}`,
                    `companyName=${encodeURIComponent(companyName)}`,
                    `companySize=${encodeURIComponent(companySize)}`,
                    `companyWebsite=${encodeURIComponent(companyWebsite)}`,
                    `countryEstablished=${encodeURIComponent(
                        countryEstablished
                    )}`,
                    `companyTurnover=${encodeURIComponent(companyTurnover)}`,
                    `licenseBrands=${encodeURIComponent(licenseBrands)}`,
                    `licenseBrandExamples=${encodeURIComponent(
                        licenseBrandExamples
                    )}`,
                    `brandInterests=${encodeURIComponent(brandInterests)}`,
                    `productType=${encodeURIComponent(productType)}`,
                ].join('&')
            }

            let response = await fetch(process.env.GATSBY_CRM_URL, {
                    method: 'POST',
                    body,
                }),
                data = await response?.json()

            setTimeout(() => {
                if (data?.statusCode === 200) {
                    setStatus(statuses.SUCCESS)
                } else {
                    setStatus(statuses.ERROR)
                }
            }, 1000)
        }
    }

    return (
        <div className="py-12 md:py-20 relative bg-primary text-left">
            <div className="container mx-auto px-8 md:px-12 lg:px-20">
                <div className="flex flex-col items-start">
                    <span className="block mb-4 font-bold text-xs text-black text-opacity-90">
                        Enquiry type
                    </span>
                    <div className="flex-0 flex md:inline-flex flex-col md:flex-row w-full md:w-auto border border-grey border-opacity-50 p-1">
                        <button
                            type="button"
                            className={`group inline-flex items-center justify-center w-full sm:w-auto h-12 px-8 sm:mt-0 font-bold text-sm text-white ${
                                enquiryType === enquiryTypes.LICENSEE
                                    ? 'bg-greyblue'
                                    : 'bg-white text-grey hover:bg-grey hover:bg-opacity-10'
                            } uppercase transition whitespace-nowrap`}
                            onClick={() => setFormType(enquiryTypes.LICENSEE)}
                        >
                            Become a Licensee
                        </button>
                        <button
                            type="button"
                            className={`group inline-flex items-center justify-center w-full sm:w-auto h-12 px-8 md:ml-1 sm:mt-0 font-bold text-sm text-white ${
                                enquiryType === enquiryTypes.GENERAL
                                    ? 'bg-greyblue'
                                    : 'bg-white text-grey hover:bg-grey hover:bg-opacity-10'
                            } uppercase transition whitespace-nowrap`}
                            onClick={() => setFormType(enquiryTypes.GENERAL)}
                        >
                            General Enquiry
                        </button>
                    </div>
                </div>

                {enquiryType === enquiryTypes?.GENERAL ? (
                    <form onSubmit={handleSubmit} className="w-full relative">
                        <div
                            className={`${
                                status === statuses.LOADING
                                    ? 'opacity-50 pointer-events-none'
                                    : 'opacity-100 pointer-events-auto'
                            } transition-all duration-200`}
                        >
                            <div className="flex flex-col lg:flex-row lg:-m-8 mt-6 lg:-mt-2">
                                <div className="w-full lg:w-1/2 lg:p-8">
                                    <label
                                        htmlFor="name"
                                        className="block mb-4 font-bold text-xs text-black text-opacity-90"
                                    >
                                        Name
                                    </label>
                                    <input
                                        required
                                        id="name"
                                        name="name"
                                        type="text"
                                        disabled={disabled}
                                        value={name}
                                        onChange={({
                                            currentTarget: { value },
                                        }) => setName(value)}
                                        className={`flex-1 flex items-center w-full h-12 px-4 border border-grey border-opacity-50 ${
                                            disabled
                                                ? 'cursor-not-allowed'
                                                : 'hover:border-opacity-70 active:border-opacity-100'
                                        } text-grey bg-transparent outline-none apeparance-none`}
                                    />
                                    <input
                                        id="contact_role"
                                        name="contact_role"
                                        type="text"
                                        value={role}
                                        autoComplete={0}
                                        tabIndex={-1}
                                        onChange={({
                                            currentTarget: { value },
                                        }) => setRole(value)}
                                        className="w-0 h-0 overflow-hidden opacity-0 apeparance-none"
                                    />
                                </div>
                                <div className="w-full lg:w-1/2 lg:p-8 mt-6 lg:mt-0">
                                    <label
                                        htmlFor="email"
                                        className="block mb-4 font-bold text-xs text-black text-opacity-90"
                                    >
                                        Email
                                    </label>
                                    <input
                                        required
                                        id="email"
                                        name="email"
                                        type="email"
                                        disabled={disabled}
                                        value={email}
                                        onChange={({
                                            currentTarget: { value },
                                        }) => setEmail(value)}
                                        className={`flex-1 flex items-center w-full h-12 px-4 border border-grey border-opacity-50 ${
                                            disabled
                                                ? 'cursor-not-allowed'
                                                : 'hover:border-opacity-70 active:border-opacity-100'
                                        } text-grey bg-transparent outline-none apeparance-none`}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col lg:flex-row lg:-m-8 mt-6 lg:-mt-2">
                                <div className="w-full lg:p-8">
                                    <label
                                        htmlFor="subject"
                                        className="block mb-4 font-bold text-xs text-black text-opacity-90"
                                    >
                                        Subject
                                    </label>
                                    <input
                                        required
                                        id="subject"
                                        name="subject"
                                        type="text"
                                        disabled={status === statuses.SUCCESS}
                                        value={subject}
                                        onChange={({
                                            currentTarget: { value },
                                        }) => setSubject(value)}
                                        className={`flex-1 flex items-center w-full h-12 px-4 border border-grey border-opacity-50 ${
                                            disabled
                                                ? 'cursor-not-allowed'
                                                : 'hover:border-opacity-70 active:border-opacity-100'
                                        } text-grey bg-transparent outline-none apeparance-none`}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col lg:flex-row lg:-m-8 mt-6 lg:-mt-2">
                                <div className="w-full lg:p-8">
                                    <label
                                        htmlFor="message"
                                        className="block mb-4 font-bold text-xs text-black text-opacity-90"
                                    >
                                        Message
                                    </label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows={8}
                                        disabled={status === statuses.SUCCESS}
                                        value={message}
                                        onChange={({
                                            currentTarget: { value },
                                        }) => setMessage(value)}
                                        className={`flex-1 flex items-center w-full p-4 border border-grey border-opacity-50 ${
                                            disabled
                                                ? 'cursor-not-allowed'
                                                : 'hover:border-opacity-70 active:border-opacity-100'
                                        } text-grey bg-transparent outline-none apeparance-none`}
                                    />
                                </div>
                            </div>
                        </div>
                        {status === statuses.SUCCESS ? (
                            <p className="mt-8 md:mt-12 font-bold text-sm text-green text-center">
                                {`Thank you for contacting us! We'll be in touch soon.`}
                            </p>
                        ) : null}

                        {/* <ReCAPTCHA
                        sitekey="6LcuwJ8UAAAAAFicwtwrsf0YcXEIonWs8a7VYg4G"
                        onChange={handleCaptchaChange}
                    /> */}

                        <div
                            className={`flex flex-wrap flex-col md:flex-row items-center justify-center ${
                                status !== statuses.SUCCESS
                                    ? 'mt-8 md:mt-12'
                                    : 'mt-4'
                            }`}
                        >
                            {status === statuses.ERROR ? (
                                <div className="flex justify-center w-full">
                                    <p className="py-2 px-3 mb-3 text-sm text-orange border border-orange">
                                        Something went wrong, please try again.
                                    </p>
                                </div>
                            ) : null}

                            {status === statuses.LOADING ? (
                                <Spinner className="text-navy" />
                            ) : (
                                <button
                                    type="submit"
                                    disabled={disabled}
                                    className={`inline-flex items-center justify-center w-full sm:w-auto h-12 px-8 mt-4 sm:mt-0 font-bold text-sm text-white bg-greyblue ${
                                        disabled
                                            ? 'opacity-50 cursor-not-allowed'
                                            : 'transform hover:bg-opacity-70 hover:scale-110'
                                    } uppercase transition`}
                                >
                                    Submit
                                </button>
                            )}
                            <label
                                htmlFor="footer_terms"
                                className="flex sm:inline-flex items-center justify-start w-full sm:w-auto mt-4 md:mt-0 md:ml-8 cursor-pointer select-none"
                            >
                                <span className="inline-flex items-center justify-center w-5 h-5 bg-grey bg-opacity-20 border-2 border-navy text-center">
                                    <SVG
                                        src={interfaceValidationCheck}
                                        className={`w-3 h-auto transform origin-center scale-50 ${
                                            acceptedTerms
                                                ? 'opacity-100 scale-100'
                                                : 'opacity-0'
                                        } text-navy transition-all duration-200`}
                                    />
                                </span>
                                <input
                                    required
                                    id="footer_terms"
                                    name="footer_terms"
                                    type="checkbox"
                                    disabled={disabled}
                                    value={acceptedTerms}
                                    onChange={() =>
                                        setAcceptedTerms(!acceptedTerms)
                                    }
                                    className="w-0 h-0 overflow-hidden opacity-0 pointer-events-none"
                                />
                                <span
                                    className={`block ml-4 font-light text-xs ${
                                        disabled
                                            ? 'opacity-50 cursor-not-allowed'
                                            : 'opacity-100'
                                    }`}
                                >
                                    Accept privacy policy
                                </span>
                            </label>
                        </div>
                    </form>
                ) : (
                    <div
                        className="mt-8 text-sm text-editor"
                        dangerouslySetInnerHTML={{
                            __html: primary?.licensee_description?.html,
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default ContactForm
