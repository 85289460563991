import React from 'react'
import cx from 'classnames'

import './style.scss'

const Toggle = ({ active = false, toggleActive }) => (
    <button
        type="button"
        onClick={typeof toggleActive === 'function' && toggleActive}
        className={cx(
            'toggle relative cursor-pointer appearance-none border-none',
            {
                active,
            }
        )}
    />
)

export default Toggle
