import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

import logo from "../../images/global/logo.svg";
import interfaceValidationCheck from "../../images/global/interface-validation-check.svg";

import SVG from "../SVG";
import Link from "../Link";
import Spinner from "../Spinner";
import { useEffect } from "react";

const statuses = {
  PENDING: "pending",
  INVALID: "invalid",
  SUCCESS: "success",
  LOADING: "loading",
  ERROR: "error",
};

const Footer = () => {
  const { data } = useMergePrismicPreviewData(
    useStaticQuery(graphql`
      query getFooter {
        config: prismicGlobalConfiguration {
          _previewable
          data {
            footer_menu {
              label
              link {
                uid
                url
              }
            }
            privacy_policy_link {
              uid
              url
            }
            privacy_policy_label
          }
        }
      }
    `)
  );

  const items = data?.config?.data?.footer_menu;
  const [status, setStatus] = useState(statuses.PENDING);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const disabled = [statuses.LOADING, statuses.SUCCESS].includes(status);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (acceptedTerms && role === "") {
      setStatus(statuses.LOADING);

      let response = await fetch(process.env.GATSBY_CRM_URL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: [
            `_to=${encodeURIComponent(
              "76bddba4a0050da0d48b7a063eaf6ad7e88a0db697ae539f28f65f4f196da746053285bc2da915103a4ab20d6618d1d70af3fa5295770f290cf405d1c7aa752035ae6586ae20b519f123929214b2ca76731deab51f4c1755b858c0072df89281f3ed7163aff3ed4aa202dc8d7d2675c63d8fab9388fc8c6739474dafd485f17017abda3ffc"
            )}`,
            `_replyTo=${encodeURIComponent(email)}`,
            `_honeypot=${encodeURIComponent(role)}`,
            `email=${encodeURIComponent(email)}`,
          ].join("&"),
        }),
        data = await response.json();

      if (data?.statusCode === 200) {
        setStatus(statuses.SUCCESS);
      } else {
        setStatus(statuses.ERROR);
      }
    }
  };

  useEffect(() => {
    if (email.length > 0) setStatus(statuses.PENDING);
  }, [email, acceptedTerms]);

  return (
    <div className="py-8 md:py-12 lg:py-20 relative bg-navy text-white">
      <div className="container mx-auto px-8 md:px-12 lg:px-20">
        <div className="flex flex-col lg:flex-row -m-8">
          <div className="w-full lg:w-1/2 p-8">
            <Link to="/">
              <img
                src={logo}
                className="block w-24 h-auto mb-8 md:mb-12 select-none"
              />
            </Link>
            {items?.length > 0 ? (
              <ul className="flex flex-wrap items-start justify-start xl:max-w-md -m-5">
                {items?.map(({ link, label }, i) => (
                  <li key={i} className="w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3 p-5">
                    <div className="group border-t border-white">
                      <Link
                        to={
                          link?.uid
                            ? `/${link?.uid === "home" ? "" : link?.uid}`
                            : link?.url
                        }
                        external={!link?.uid}
                        className="block pt-3 font-bold text-xs text-white uppercase"
                      >
                        <span className="block transform group-hover:translate-x-2 transition">
                          {label}
                        </span>
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
          <div className="flex flex-col justify-center w-full lg:w-1/2 p-8">
            <form onSubmit={handleSubmit} className="w-full relative">
              <div
                className={`flex items-center justify-center w-full h-full absolute top-0 left-0 text-center transform origin-center ${
                  status === statuses.LOADING
                    ? "scale-100 opacity-100 visible"
                    : "scale-0 opacity-0 invisible"
                } pointer-events-none transition-all duration-200`}
              >
                <Spinner className="text-white" />
              </div>

              <div
                className={`flex flex-col ${
                  status === statuses.LOADING
                    ? "opacity-10 pointer-events-none"
                    : "opacity-100 pointer-events-auto"
                } transition-all duration-200`}
              >
                <h3
                  className={`flex-0 font-bold text-lg ${
                    status !== statuses.SUCCESS
                      ? "mb-4 md:mb-8 text-white"
                      : "mb-2 text-green"
                  }`}
                >
                  {status !== statuses.SUCCESS
                    ? "Sign up to our Product Newsletter"
                    : "Thank you for subscribing!"}
                </h3>
                {status === statuses.SUCCESS ? (
                  <p className="mb-4 md:mb-8 text-sm text-white">
                    You will receive an email to confirm your subscription.
                  </p>
                ) : null}

                <input
                  required
                  type="email"
                  value={email}
                  onChange={({ currentTarget: { value } }) => setEmail(value)}
                  placeholder="Email address"
                  disabled={disabled}
                  className={`flex-0 flex items-center h-12 px-4 border border-darkgrey ${
                    disabled
                      ? "border-opacity-50 text-opacity-50 cursor-not-allowed"
                      : "hover:border-grey focus:border-white active:border-white"
                  } font-bold text-grey bg-transparent outline-none apeparance-none`}
                />
                <input
                  id="footer_role"
                  name="footer_role"
                  type="text"
                  value={role}
                  disabled={disabled}
                  onChange={({ currentTarget: { value } }) => setRole(value)}
                  className="w-0 h-0 overflow-hidden opacity-0 apeparance-none"
                />

                <div className="flex flex-wrap flex-col-reverse sm:flex-row items-center justify-start mt-4 md:mt-8">
                  {status === statuses.ERROR ? (
                    <p className="w-full py-2 px-3 mb-3 text-sm text-orange border border-orange">
                      Something went wrong, please try again.
                    </p>
                  ) : null}

                  <button
                    type="submit"
                    disabled={disabled}
                    className={`inline-flex items-center justify-center w-full sm:w-auto h-12 px-8 mt-4 sm:mt-0 font-bold text-sm text-white bg-greyblue ${
                      disabled
                        ? "opacity-50 cursor-not-allowed"
                        : "transform hover:scale-110"
                    } uppercase transition select-none`}
                  >
                    Submit
                  </button>
                  <label
                    htmlFor="terms"
                    className="flex sm:inline-flex items-center justify-start w-full sm:w-auto sm:ml-8 relative cursor-pointer select-none"
                  >
                    <span className="inline-flex items-center justify-center w-5 h-5 bg-white text-center">
                      <SVG
                        src={interfaceValidationCheck}
                        className={`w-3 h-auto transform origin-center scale-50 ${
                          acceptedTerms ? "text-navy scale-100" : "text-white"
                        } transition-all duration-200`}
                      />
                    </span>
                    <input
                      required
                      id="terms"
                      name="terms"
                      type="checkbox"
                      disabled={disabled}
                      value={acceptedTerms}
                      onChange={() => setAcceptedTerms(!acceptedTerms)}
                      className="absolute top-0 left-0 opacity-0 pointer-events-none"
                    />
                    <span
                      className={`block ml-4 font-light text-xs ${
                        disabled
                          ? "opacity-50 cursor-not-allowed"
                          : "opacity-100"
                      }`}
                    >
                      Accept privacy policy
                    </span>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>

        {data?.config?.data?.privacy_policy_link ? (
          <Link
            to={
              data?.config?.data?.privacy_policy_link?.uid
                ? `/${data?.config?.data?.privacy_policy_link?.uid}`
                : data?.config?.data?.privacy_policy_link?.url
            }
            className="inline-block mt-8 md:mt-12 font-light text-xs"
          >
            {data?.config?.data?.privacy_policy_label ?? "Privacy Policy"}
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default Footer;
