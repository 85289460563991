import React from 'react'

import './style.scss'

const TextEditor = ({ primary: _primary, textEditorPrimary }) => {
    const primary = textEditorPrimary || _primary

    return primary?.content?.html ? (
        <div className="text-editor py-12 md:py-20 relative bg-primary text-center xl:text-left">
            <div className="container mx-auto px-8 md:px-12 lg:px-20">
                <div
                    dangerouslySetInnerHTML={{ __html: primary?.content?.html }}
                />
            </div>
        </div>
    ) : null
}

export default TextEditor