import React, { createContext, useState, useContext } from 'react'

const ProviderContext = createContext()

/**
 * SearchProvider
 * 
 * @param {mixed} children
 */
const SearchProvider = ({ children }) => {
	const [ query, setQuery ] = useState('')

	return (
		<ProviderContext.Provider value={{ query, setQuery }}>
			{ children }
		</ProviderContext.Provider>
	)
}

/**
 * useSearchState
 */
const useSearchState = () => {
	const context = useContext(ProviderContext)

	if (context === undefined) throw new Error('useSearchState must be used within a SearchProvider')

	return context
}

export { SearchProvider, useSearchState }
