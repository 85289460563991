import React from 'react'
import ReactInlineSVG from 'react-inlinesvg'

const SVG = ({ src, clickHandler, className }) => (
    <ReactInlineSVG
        {...{
            src,
            onClick: (e) =>
                typeof clickHandler === 'function' && clickHandler({ e }),
            className,
        }}
    />
)

export default SVG
