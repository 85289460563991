import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import interfaceArrowsButtonLeft from '../../../images/global/interface-arrows-button-left.svg'
import interfaceArrowsButtonRight from '../../../images/global/interface-arrows-button-right.svg'

import WithIsVisible from '../../WithIsVisible'
import Link from '../../Link'
import SVG from '../../SVG'

const ImageBlock = ({ images }) => {
    const _images = images?.filter(({ small, localFile }) => {
        if (
            typeof small === 'undefined' &&
            typeof localFile?.publicURL !== 'undefined'
        )
            return true
        if (small !== null && typeof small !== 'undefined') return true

        return false
    })

    return _images?.length > 2 ? (
        <div className="relative flex flex-col w-full">
            <div className="flex-1 flex flex-col md:flex-row w-full h-1/2 bg-white bg-opacity-50">
                {_images?.map((image, i) =>
                    i < 2 ? (
                        <div
                            key={i}
                            className={`${
                                i === 0 ? 'block' : 'hidden md:block'
                            } flex-1 h-full w-full md:w-1/2 relative`}
                        >
                            <div className="w-full xl:h-full xl:absolute xl:top-0 xl:left-0">
                                {image?.small ? (
                                    <GatsbyImage
                                        image={getImage(image?.small)}
                                        className="w-full xl:h-full xl:absolute xl:top-0 xl:left-0"
                                        alt="Ministry of Defence"
                                    />
                                ) : (
                                    <div
                                        style={{
                                            backgroundImage: `url(${image?.localFile?.publicURL})`,
                                        }}
                                        className="w-full xl:h-full xl:absolute xl:top-0 xl:left-0 pt-3/4 xl:pt-0 bg-cover bg-center"
                                    />
                                )}
                            </div>
                        </div>
                    ) : null
                )}
            </div>
            <div className="hidden md:flex flex-1 flex w-full h-1/2">
                {_images?.map((image, i) =>
                    i >= 2 ? (
                        <div key={i} className="flex-1 h-full w-1/2 relative">
                            <div className="w-full xl:h-full xl:absolute xl:top-0 xl:left-0">
                                {image?.small ? (
                                    <GatsbyImage
                                        image={getImage(image?.small)}
                                        className="w-full xl:h-full xl:absolute xl:top-0 xl:left-0"
                                        alt="Ministry of Defence"
                                    />
                                ) : (
                                    <img
                                        src={image?.localFile?.publicURL}
                                        className="w-full xl:h-full xl:absolute xl:top-0 xl:left-0"
                                        alt="Ministry of Defence"
                                    />
                                )}
                            </div>
                        </div>
                    ) : null
                )}
            </div>
        </div>
    ) : (
        <div className="flex w-full h-full relative">
            {_images?.map((image, i) => (
                <div
                    key={i}
                    className={`${
                        _images?.length === 1
                            ? 'w-full pt-3/4 xl:pt-0'
                            : 'w-full md:w-1/2 pt-3/4 md:pt-1/2 xl:pt-0'
                    } ${i > 0 ? 'hidden md:block' : 'block'} relative`}
                >
                    <div className="w-full h-full absolute top-0 left-0">
                        {image?.full || image?.half ? (
                            <GatsbyImage
                                image={
                                    _images?.length === 1
                                        ? getImage(image?.full)
                                        : getImage(image?.half)
                                }
                                className="w-full h-full absolute top-0 left-0"
                                alt="Ministry of Defence"
                            />
                        ) : (
                            <div
                                style={{
                                    backgroundImage: `url(${image?.localFile?.publicURL})`,
                                }}
                                className="w-full h-full absolute top-0 left-0 bg-cover bg-center"
                            />
                        )}
                    </div>
                </div>
            ))}
        </div>
    )
}

const ProductsCarousel = ({
    primary: _primary,
    carouselProductsPrimary,
    items: _items,
    carouselProductsItems,
    accent_colour,
}) => {
    const primary = carouselProductsPrimary || _primary
    const items = carouselProductsItems || _items
    const [activeIndex, setActiveIndex] = useState(0)

    const handlePrev = () => {
        setActiveIndex(activeIndex === 0 ? items?.length - 1 : activeIndex - 1)
    }

    const handleNext = () => {
        setActiveIndex(
            activeIndex + 1 > items?.length - 1 ? 0 : activeIndex + 1
        )
    }

    return items?.length > 0 ? (
        <div
            className={`${
                primary?.space_top === 'No'
                    ? 'pb-12 md:pb-20'
                    : 'py-12 md:py-20'
            } relative bg-primary text-center xl:text-left`}
        >
            <WithIsVisible
                children={({ isVisible, active }) => (
                    <div className="container mx-auto px-8 md:px-12 lg:px-20">
                        {primary?.heading ? (
                            <h2 className="font-bold text-4xl md:text-5xl xl:text-7xl text-black text-opacity-5 uppercase select-none">
                                <span
                                    className={`inline-block transform ${
                                        isVisible || !active
                                            ? 'translate-x-0 opacity-100'
                                            : '-translate-x-32 opacity-0'
                                    } transition duration-500`}
                                >
                                    {primary?.heading}
                                </span>
                            </h2>
                        ) : null}

                        <div className="flex flex-col xl:flex-row mt-8 md:mt-12 text-white bg-navy">
                            <div className="flex-1 flex w-full xl:w-2/3">
                                <ImageBlock
                                    {...{
                                        images: [
                                            items?.[activeIndex]?.image_one ||
                                                null,
                                            items?.[activeIndex]?.image_two ||
                                                null,
                                            items?.[activeIndex]?.image_three ||
                                                null,
                                            items?.[activeIndex]?.image_four ||
                                                null,
                                        ],
                                    }}
                                />
                            </div>
                            <div className="w-full xl:w-1/3 flex flex-col justify-between p-8 xl:p-12 pb-0 xl:pb-0">
                                <div>
                                    {items?.map(
                                        (
                                            {
                                                subheading,
                                                heading,
                                                description,
                                                link,
                                                button_label,
                                                armed_force,
                                            },
                                            i
                                        ) => (
                                            <div
                                                key={i}
                                                className={`${
                                                    activeIndex === i
                                                        ? 'block'
                                                        : 'hidden'
                                                }`}
                                            >
                                                <span className="font-bold text-sm tracking-widest text-white uppercase">
                                                    {subheading}
                                                </span>
                                                <h2 className="mt-4 mb-8 font-bold text-xl md:text-2xl">
                                                    {heading}
                                                </h2>
                                                <div className="xl:min-h-32 xl2:min-h-64 text-xs">
                                                    <span className="block max-w-sm mx-auto xl:mx-0">
                                                        {description}
                                                    </span>
                                                    {link && button_label ? (
                                                        <div>
                                                            <Link
                                                                to={
                                                                    link?.uid
                                                                        ? `/${
                                                                              link?.uid ===
                                                                              'home'
                                                                                  ? ''
                                                                                  : link?.uid
                                                                          }`
                                                                        : link?.url
                                                                }
                                                                external={
                                                                    !link?.uid
                                                                }
                                                                className="group inline-flex items-center justify-center w-full sm:w-auto h-12 px-8 mt-4 md:mt-8 transform hover:scale-110 duration-200 font-bold text-sm text-white bg-blue uppercase transition"
                                                                style={{
                                                                    backgroundColor:
                                                                        accent_colour,
                                                                }}
                                                            >
                                                                {button_label}
                                                                <SVG
                                                                    src={
                                                                        interfaceArrowsButtonRight
                                                                    }
                                                                    className="flex-0 w-3 h-auto ml-4 text-white relative z-10"
                                                                />
                                                            </Link>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                                <div className="mt-8 xl:mt-12">
                                    <ul className="flex justify-center h-8 mb-4">
                                        {items?.map((item, i) => (
                                            <li key={i}>
                                                <button
                                                    className="inline-flex items-center justify-center w-4 h-4"
                                                    onClick={() =>
                                                        setActiveIndex(i)
                                                    }
                                                >
                                                    <span
                                                        className={`w-1 h-1 bg-white ${
                                                            activeIndex === i
                                                                ? 'bg-opacity-100 pointer-events-none'
                                                                : 'bg-opacity-50'
                                                        } rounded-full select-none`}
                                                    />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="flex items-center justify-between h-12 -mx-8 xl:-mx-12 bg-greyblue">
                                        <button
                                            type="button"
                                            className="group inline-flex items-center justify-start h-12 w-1/2 px-8 relative font-bold text-sm text-white uppercase transition"
                                            onClick={handlePrev}
                                        >
                                            <span
                                                className="block w-full h-full absolute top-0 left-0 bg-grey pointer-events-none opacity-0 group-hover:opacity-100 invisible group-hover:visible transition"
                                                style={{
                                                    backgroundColor:
                                                        accent_colour,
                                                }}
                                            />
                                            <SVG
                                                src={interfaceArrowsButtonLeft}
                                                className="flex-0 w-3 h-auto mr-4 text-white relative z-10"
                                            />
                                            <span className="block transform group-hover:scale-110 transition duration-100">
                                                Prev
                                                <span className="hidden sm:inline xl:hidden 2xl:inline">
                                                    ious
                                                </span>
                                            </span>
                                        </button>
                                        <button
                                            type="button"
                                            className="group inline-flex items-center justify-end h-12 w-1/2 px-8 relative font-bold text-sm text-white uppercase transition"
                                            onClick={handleNext}
                                        >
                                            <span
                                                className="block w-full h-full absolute top-0 left-0 bg-grey pointer-events-none opacity-0 group-hover:opacity-100 invisible group-hover:visible transition"
                                                style={{
                                                    backgroundColor:
                                                        accent_colour,
                                                }}
                                            />
                                            <span className="block transform group-hover:scale-110 transition duration-100">
                                                Next
                                            </span>
                                            <SVG
                                                src={interfaceArrowsButtonRight}
                                                className="flex-0 w-3 h-auto ml-4 text-white relative z-10"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            />
        </div>
    ) : null
}

export default ProductsCarousel
