import React from 'react'
import { Helmet } from 'react-helmet'

import appleTouch from '../../images/apple-touch-icon.png'
import favicon16 from '../../images/favicon-16x16.png'
import favicon32 from '../../images/favicon-32x32.png'
import safariPinnedTab from '../../images/safari-pinned-tab.svg'

const Meta = ({ config: _default, meta }) => {
    const title = meta?.title ?? _default?.page_title
    const description = meta?.description ?? _default?.meta_description
    const shareImage = meta?.shareImage?.url ?? _default?.share_image?.url
    const cardType = 'summary_large_image'

    return (
        <Helmet>
            <meta name="robots" content="noindex" />

            <link rel="apple-touch-icon" sizes="180x180" href={appleTouch} />
            <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
            <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
            <link rel="mask-icon" href={safariPinnedTab} color="#000b1d" />
            <meta name="msapplication-TileColor" content="#000b1d" />
            <meta name="theme-color" content="#ffffff" />

            <title>{title}</title>
            <meta name="description" content={description} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={shareImage} />

            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={shareImage} />
            <meta name="twitter:card" content={cardType} />
        </Helmet>
    )
}

export default Meta
