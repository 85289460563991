import React, { createContext, useState, useContext } from 'react'
import { isMobileOnly, isMobile, isTablet, isIE } from 'react-device-detect'

const BrowserContext = createContext()

/**
 * BrowserProvider
 * 
 * @param {mixed} children
 */
const BrowserProvider = ({ children }) => {
	const [ browser ] = useState(isIE ? 'ie' : 'modern')
	const [ themeSettings ] = useState({ isMobile, isMobileOnly, isTablet })

	return (
		<BrowserContext.Provider value={{ browser, ...themeSettings }}>
			{ children }
		</BrowserContext.Provider>
	)
}

/**
 * useBrowserState
 */
const useBrowserState = () => {
	const context = useContext(BrowserContext)

	if (context === undefined) throw new Error('useBrowserState must be used within a BrowserProvider')

	return context
}

export { BrowserProvider, useBrowserState }
