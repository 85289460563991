import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import interfaceArrowsButtonRight from '../../../images/global//interface-arrows-button-right.svg'

import WithIsVisible from '../../WithIsVisible'
import Link from '../../Link'
import SVG from '../../SVG'

const FeaturedProduct = ({ primary: _primary, featuredProductPrimary }) => {
    const primary = featuredProductPrimary || _primary

    return (
        <div className="py-12 md:py-20 relative bg-primary text-center md:text-left">
            <div className="container mx-auto px-8 md:px-12 lg:px-20">
                {primary?.background_text ? (
                    <h2 className="font-bold text-4xl md:text-5xl xl:text-7xl text-black text-opacity-5 uppercase select-none">
                        <WithIsVisible
                            children={({ isVisible, active }) => (
                                <span
                                    className={`inline-block transform ${
                                        isVisible || !active
                                            ? 'translate-x-0 opacity-100'
                                            : '-translate-x-32 opacity-0'
                                    } transition duration-500`}
                                >
                                    {primary?.background_text}
                                </span>
                            )}
                        />
                    </h2>
                ) : null}

                <div className="flex flex-col md:flex-row-reverse mt-8 md:mt-12 lg:mt-20">
                    <WithIsVisible
                        children={({ isVisible, active }) => (
                            <>
                                <div className="flex-1 flex flex-col w-full md:w-1/2 lg:w-3/5 xl:w-2/3">
                                    <div className="flex-1 flex flex-col md:flex-row justify-center h-full select-none pointer-events-none">
                                        <div
                                            className={`w-full md:w-10 h-5 md:h-full ${
                                                !primary?.armed_force?.document
                                                    ?.accent_colour
                                                    ? 'bg-lightgrey'
                                                    : ''
                                            }`}
                                            style={{
                                                backgroundColor:
                                                    primary?.armed_force
                                                        ?.document
                                                        ?.accent_colour,
                                            }}
                                        />
                                        {primary?.image_one ? (
                                            <GatsbyImage
                                                image={getImage(
                                                    primary?.image_two
                                                        ? primary?.image_one
                                                              .square ||
                                                              primary?.image_one
                                                                  ?.localFile
                                                                  ?.childImageSharp
                                                                  ?.gatsbyImageData
                                                        : primary?.image_one
                                                              .wide ||
                                                              primary?.image_one
                                                                  ?.localFile
                                                                  ?.childImageSharp
                                                                  ?.gatsbyImageData
                                                )}
                                                className="-mr-px"
                                                alt="Ministry of Defence"
                                            />
                                        ) : null}
                                        {primary?.image_two ? (
                                            <GatsbyImage
                                                image={getImage(
                                                    primary?.image_one
                                                        ? primary?.image_two
                                                              .square ||
                                                              primary?.image_one
                                                                  ?.localFile
                                                                  ?.childImageSharp
                                                                  ?.gatsbyImageData
                                                        : primary?.image_two
                                                              .wide ||
                                                              primary?.image_one
                                                                  ?.localFile
                                                                  ?.childImageSharp
                                                                  ?.gatsbyImageData
                                                )}
                                                className={`${
                                                    primary?.image_one
                                                        ? 'hidden xl:block'
                                                        : 'block'
                                                }`}
                                                alt="Ministry of Defence"
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center items-center md:items-start w-full md:w-1/2 lg:w-2/5 xl:w-1/3 md:pr-12">
                                    {primary?.subheading ? (
                                        <span
                                            className={`${
                                                primary?.image_one ||
                                                primary?.image_two
                                                    ? 'mt-4 md:mt-0'
                                                    : 'mt-0'
                                            } font-bold text-lg md:text-xl text-black text-darkgrey tracking-widest uppercase`}
                                        >
                                            <span
                                                className={`inline-block transform ${
                                                    isVisible || !active
                                                        ? 'translate-y-0 opacity-100'
                                                        : '-translate-y-12 opacity-0'
                                                } transition duration-1000 delay-200`}
                                            >
                                                {primary?.subheading}
                                            </span>
                                        </span>
                                    ) : null}
                                    <h3 className="my-4 font-bold text-lg text-navy">
                                        <span
                                            className={`inline-block transform ${
                                                isVisible || !active
                                                    ? 'translate-y-0 opacity-100'
                                                    : '-translate-y-12 opacity-0'
                                            } transition duration-1000`}
                                        >
                                            Product Name
                                        </span>
                                    </h3>
                                    <div className="max-w-xs mx-auto md:mx-0 mb-8 font-light text-xs text-navy">
                                        <div
                                            className={`inline-block transform ${
                                                isVisible || !active
                                                    ? 'translate-y-0 opacity-100'
                                                    : '-translate-y-12 opacity-0'
                                            } transition duration-1000 delay-400`}
                                            dangerouslySetInnerHTML={{
                                                __html: primary?.description
                                                    ?.html,
                                            }}
                                        ></div>
                                    </div>
                                    <Link
                                        to={primary?.product?.uid}
                                        className={`group inline-flex items-center justify-center h-10 px-4 transform ${
                                            isVisible || !active
                                                ? 'scale-100 opacity-100'
                                                : 'scale-50 opacity-0'
                                        } font-bold text-sm text-white bg-blue hover:bg-opacity-80 uppercase transition-all delay-500 duration-500`}
                                    >
                                        <span className="inline-block transform group-hover:translate-x-1.5 transition-all duration-100">
                                            {primary?.button_text}
                                        </span>
                                        <SVG
                                            src={interfaceArrowsButtonRight}
                                            className="w-3 h-auto ml-4 text-white relative z-10"
                                        />
                                    </Link>
                                </div>
                            </>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

export default FeaturedProduct
