import React, { useState } from 'react'

import interfaceSearch from '../../images/global/interface-search.svg'
import interfaceAscending from '../../images/global/interface-ascending.svg'
import interfaceDescending from '../../images/global/interface-descending.svg'

import Link from '../Link'
import SVG from '../SVG'

const Licensees = ({ primary: _primary, licenseePrimary, licensees }) => {
    const primary = licenseePrimary || _primary
    const [showFirst, setShowFirst] = useState(12)
    const [query, setQuery] = useState('')
    const [tag, setTag] = useState([])
    const [asc, setAsc] = useState(true)
    const _tags = licensees?.reduce((tags, { node }) => {
        let newTags = []

        node?.tags?.forEach((tag) => {
            if (tags.indexOf(tag) + newTags.indexOf(tag) === -2)
                newTags.push(tag)
        })

        return [...tags, ...newTags]?.sort((tagA, tagB) => {
            if (tagA < tagB) return -1
            if (tagA > tagB) return 1
            return 0
        })
    }, [])
    const _licensees = licensees
        ?.filter(({ node: { data, tags } }) => {
            let _valid = true

            if (
                tag?.length > 0 &&
                tags?.filter((_tag) => tag?.includes(_tag))?.length === 0
            )
                _valid = false

            if (
                query?.length > 0 &&
                data?.name?.toLowerCase()?.indexOf(query?.toLowerCase()) === -1
            )
                _valid = false

            return _valid
        })
        ?.sort(({ node: { data: dataA } }, { node: { data: dataB } }) => {
            if (dataA?.name < dataB?.name) return asc ? -1 : 1
            if (dataA?.name > dataB?.name) return asc ? 1 : -1
            return 0
        })

    const handleSortClick = () => {
        setAsc(!asc)
    }

    const handleTagClick = (newTag) => {
        if (tag?.includes(newTag)) {
            setTag(tag?.filter((_tag) => _tag !== newTag))
        } else {
            setTag([...tag, newTag])
        }
    }

    return licensees?.length > 0 ? (
        <div className="licensees py-12 md:py-20 relative bg-primary text-center xl:text-left">
            <div className="container mx-auto px-8 md:px-12 lg:px-20">
                {primary?.heading ? (
                    <h2 className="mt-4 mb-8 font-bold text-2xl md:text-3xl lg:text-4xl">
                        {primary?.heading}
                    </h2>
                ) : null}

                <div className="flex flex-col text-center xl:text-left">
                    <div className="flex items-center justify-between lg:justify-start flex-0 w-full lg:w-1/2 lg:mb-8">
                        <div className="mr-4 relative">
                            <input
                                name="query"
                                type="text"
                                placeholder="Search licensees"
                                value={query}
                                className="inline-flex flex-1 items-center h-12 px-4 pr-10 border-2 border-grey border-opacity-50"
                                onChange={({ currentTarget: { value } }) =>
                                    setQuery(value)
                                }
                            />
                            <SVG
                                src={interfaceSearch}
                                className="w-4 h-auto absolute top-1/2 right-3 transform -translate-y-1/2"
                            />
                        </div>

                        <button
                            type="button"
                            className="inline-flex items-center justify-center h-12 px-4 text-center border-2 border-grey border-opacity-50"
                            onClick={handleSortClick}
                        >
                            <SVG
                                src={
                                    asc
                                        ? interfaceAscending
                                        : interfaceDescending
                                }
                                className="w-4 h-auto"
                            />
                        </button>
                    </div>

                    {primary?.description?.html ? (
                        <div
                            className="mt-8 lg:mt-0 mb-4 lg:mb-8 text-left"
                            dangerouslySetInnerHTML={{
                                __html: primary?.description?.html,
                            }}
                        />
                    ) : null}

                    <div className="flex-0 w-full mt-4 lg:mt-0">
                        {_tags?.length ? (
                            <div className="flex flex-col lg:flex-row items-start lg:items-center lg:justify-start -m-2">
                                <span className="flex-0 text-xs p-2">
                                    Shop by category:
                                </span>

                                <div className="flex-1 flex flex-wrap items-center justify-start p-2">
                                    <button
                                        className={`inline-flex items-center justify-center h-8 px-2 m-1 border border-grey ${
                                            tag?.length === 0
                                                ? 'bg-grey bg-opacity-30 border-opacity-0'
                                                : 'bg-transparent border-opacity-30'
                                        } text-xxs md:text-xs text-center appearance-none`}
                                        onClick={() => setTag([])}
                                    >
                                        All Products
                                    </button>

                                    {_tags?.map((_tag, i) => (
                                        <button
                                            key={i}
                                            className={`inline-flex items-center justify-center h-8 px-2 m-1 border border-grey ${
                                                tag?.includes(_tag)
                                                    ? 'bg-grey bg-opacity-30 border-opacity-0'
                                                    : 'bg-transparent border-opacity-30'
                                            } text-xxs md:text-xs text-center appearance-none`}
                                            onClick={() => handleTagClick(_tag)}
                                        >
                                            {_tag}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>

                {_licensees?.map(({ node }, _index) => (
                    <div
                        key={_index}
                        className={`mt-8 md:mt-12 pt-8 md:pt-12 border-t-4 border-grey border-opacity-50 ${
                            _index >= showFirst ? 'hidden' : 'block'
                        }`}
                    >
                        <div className="flex flex-col xl:flex-row items-center justify-center xl:justify-between">
                            <div className="flex-0">
                                {node?.data?.name ? (
                                    <h2 className="font-bold text-xl md:text-2xl">
                                        {node?.data?.name}
                                    </h2>
                                ) : null}
                                {node?.data?.bio?.html ? (
                                    <div
                                        className="mt-2 text-navy text-opacity-50 text-sm"
                                        dangerouslySetInnerHTML={{
                                            __html: node?.data?.bio?.html,
                                        }}
                                    />
                                ) : null}
                            </div>

                            {node?.tags?.length ? (
                                <div className="flex items-center justify-center xl:justify-end mt-2 xl:-mt-1 -m-1 flex-wrap">
                                    {node?.tags?.map((tag, i) => (
                                        <div className="p-1">
                                            <span
                                                key={i}
                                                className="inline-flex items-center justify-center h-6 px-2 bg-grey bg-opacity-30 text-xs"
                                            >
                                                {tag}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                        {node?.data?.link?.url ? (
                            <Link
                                to={node?.data?.link?.url}
                                external={true}
                                className="cursor-pointer group inline-flex items-center justify-center w-full sm:w-auto h-12 px-8 mt-4 transform hover:scale-110 duration-200 font-bold text-sm text-white bg-blue uppercase transition"
                            >
                                View Website
                            </Link>
                        ) : null}
                    </div>
                ))}

                {_licensees?.length && _licensees?.length > showFirst ? (
                    <div className="flex flex-col items-center justify-center mt-8 md:mt-12 pt-8 md:pt-12 text-center border-t-2 border-grey border-opacity-50">
                        <span className="mb-4 text-sm text-black text-opacity-50">
                            Showing {showFirst} of {_licensees?.length}{' '}
                            licensees
                        </span>
                        <button
                            type="button"
                            className="cursor-pointer group inline-flex items-center justify-center h-14 px-12 mt-4 sm:mt-0 transform translate-y-0 opacity-100 font-bold text-md text-white bg-blue hover:bg-greyblue uppercase transition transition transform hover:scale-110 duration-200 delay-400"
                            onClick={() => setShowFirst(showFirst + 12)}
                        >
                            Show more
                        </button>
                    </div>
                ) : null}
            </div>
        </div>
    ) : null
}

export default Licensees
