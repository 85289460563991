import React from 'react'
import GatsbyBackgroundImage from 'gatsby-background-image'

const Container = ({ fluid, className, children }) => (
    <>
        {fluid ? (
            <GatsbyBackgroundImage
                {...{ fluid, className }}
                style={{ position: 'relative' }}
            >
                {children}
            </GatsbyBackgroundImage>
        ) : (
            <div {...{ className }}>{children}</div>
        )}
    </>
)

export default Container
