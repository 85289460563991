import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import Cookies from 'js-cookie'

import Settings from './components/Settings'

import './style.scss'

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const location = useLocation()

    const initTracking = () => {
        initializeAndTrack(location)
    }

    useEffect(() => {
        setShowBanner(Cookies.get('swh-gdpr-responded') !== 'true')
    }, [])

    useEffect(() => {
        initTracking()
    }, [Cookies.get('swh-gdpr-responded')])

    const handleAccept = () => {
        Cookies.set('mod-gdpr-google-analytics', true, { expires: 365 })
        handleCloseAll()
    }

    const handleDecline = () => {
        Cookies.remove('mod-gdpr-google-analytics')
        handleCloseAll()
    }

    const handleCloseAll = () => {
        setShowSettings(false)
        setShowBanner(false)

        Cookies.set('swh-gdpr-responded', true, { expires: 365 })
    }

    return (
        <>
            <div
                className={`cookie-banner ${
                    showBanner && !showSettings ? 'block' : 'hidden'
                } w-full p-8 fixed bottom-4 sm:bottom-8 right-4 sm:right-8 text-center md:text-left shadow-xl bg-white border-b-8 border-grey z-50`}
            >
                <p className="mb-4 text-xs">
                    Our site uses cookies and similar technologies. Some are
                    essential and some improve the experience. Please click
                    ‘accept’ to accept all cookies. Please click ‘decline’ to
                    keep all cookies off except those that are necessary.
                </p>
                <div className="flex flex-col md:flex-row items-center">
                    <div className="flex items-center">
                        <button
                            className="group inline-flex items-center justify-center w-full sm:w-auto h-10 px-4 sm:mt-0 font-bold text-xs text-white bg-greyblue hover:bg-opacity-70 uppercase transition"
                            onClick={() => handleAccept()}
                        >
                            <span className="block transform group-hover:-translate-0.5 transition-all">
                                Accept
                            </span>
                        </button>
                        <button
                            onClick={() => handleDecline()}
                            className="group inline-flex items-center justify-center w-full sm:w-auto h-10 px-4 ml-2 sm:mt-0 font-bold text-xs text-white bg-grey hover:bg-opacity-70 uppercase transition"
                        >
                            <span className="block transform group-hover:-translate-0.5 transition-all">
                                Decline
                            </span>
                        </button>
                    </div>
                    <button
                        onClick={() => setShowSettings(true)}
                        className="mt-4 md:mt-0 md:ml-4 text-xs text-navy hover:underline"
                    >
                        Configure
                    </button>
                </div>
            </div>
            {showSettings && (
                <Settings
                    {...{
                        handleAccept,
                        handleDecline,
                        initTracking,
                        handleCloseAll,
                    }}
                />
            )}
        </>
    )
}

export default CookieBanner
