import React from 'react'

import Header from '../Header'
import Footer from '../Footer'
import CookieBanner from '../CookieBanner'

const Root = ({ children, location, forceHeader = false, accent_colour }) => (
    <>
        <Header {...{ location, forceHeader }} />
        {children}
        <CookieBanner />
        <Footer {...{ accent_colour }} />
    </>
)

export default Root
