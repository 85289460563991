import React from 'react'

import Background from '../Background'
import WithIsVisible from '../WithIsVisible'
import Link from '../Link'

const BackgroundImageWithButton = ({
    primary: _primary,
    backgroundImageWithButtonPrimary,
    accent_colour,
}) => {
    const primary = backgroundImageWithButtonPrimary || _primary

    return (
        <Background
            fluid={primary?.background_image?.fluid}
            className="py-20 lg:py-32 bg-center bg-cover bg-norepeat text-center"
        >
            <WithIsVisible
                children={({ isVisible, active }) => (
                    <>
                        <span className="block w-full h-full absolute top-0 left-0 bg-jetblack opacity-60 z-10" />
                        <div className="container mx-auto px-8 md:px-12 lg:px-20 relative z-20">
                            <h2 className="mb-4 md:mb-8 lg:mb-12 font-bold text-4xl md:text-5xl lg:text-7xl text-white">
                                <span
                                    className={`inline-block transform ${
                                        isVisible || !active
                                            ? 'translate-y-0 opacity-100'
                                            : '-translate-y-8 opacity-0'
                                    } transition duration-500 delay-200`}
                                >
                                    {primary?.heading}
                                </span>
                            </h2>

                            {primary?.link && primary?.button_label ? (
                                <Link
                                    to={
                                        primary?.link?.url ||
                                        `/${primary?.link?.uid}`
                                    }
                                    external={primary?.link?.url}
                                    className={`group inline-flex items-center justify-center h-14 px-12 mt-4 sm:mt-0 transform ${
                                        isVisible || !active
                                            ? 'translate-y-0 opacity-100'
                                            : 'translate-y-8 opacity-0'
                                    } font-bold text-md text-white bg-blue hover:bg-greyblue uppercase transition transition transform hover:scale-110 duration-200 delay-400`}
                                    style={{ backgroundColor: accent_colour }}
                                >
                                    {primary?.button_label}
                                </Link>
                            ) : null}
                        </div>
                    </>
                )}
            />
        </Background>
    )
}

export default BackgroundImageWithButton
