import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import interfaceArrowsButtonRight from '../../../images/global//interface-arrows-button-right.svg'

import Link from '../../Link'
import SVG from '../../SVG'
import Background from '../../Background'

const ForcesHero = ({
    items: _items,
    forcesHeroItems,
    primary: _primary,
    forcesHeroPrimary,
}) => {
    const primary = forcesHeroPrimary || _primary
    const items = forcesHeroItems || _items

    return items?.length > 0 ? (
        <div className="flex flex-col md:flex-row-reverse w-full md:h-screen-4/5 relative">
            <div className="flex-1 pt-9/16 pt-0 relative">
                <div className="w-full h-full md:h-screen-4/5 absolute top-0 left-0">
                    <Background
                        fluid={primary?.background_image?.fluid}
                        className="w-full h-full absolute top-0 left-0 bg-cover bg-bottom z-0"
                        style={{ position: 'absolute!important' }}
                    />
                    <span className="block w-full h-full absolute top-0 left-0 bg-navy opacity-50" />

                    {primary?.heading ? (
                        <h1 className="w-full max-w-md md:max-w-3xl px-8 md:px-12 absolute bottom-8 md:bottom-12 left-1/2 transform -translate-x-1/2 font-semibold text-2xl md:text-3xl lg:text-4xl text-center text-white z-20">
                            {primary?.heading}
                        </h1>
                    ) : null}
                </div>
            </div>
            <div className="flex-0 flex flex-col w-full md:w-56 lg:w-128 h-full bg-navy">
                {items?.map(
                    (
                        {
                            armed_force: {
                                uid,
                                document: { data },
                            },
                        },
                        i
                    ) => (
                        <Link
                            to={uid}
                            key={i}
                            className="group flex-0 md:flex-1 flex items-center h-20 pl-12 pr-8 relative overflow-hidden"
                        >
                            {data?.accent_colour ? (
                                <span
                                    className="block w-full h-double absolute top-1/2 left-4 t group-hover:left-0 transform -translate-y-1/2 -translate-x-full group-hover:translate-x-0 transition-all duration-300 pointer-events-none"
                                    style={{
                                        backgroundColor: data?.accent_colour,
                                    }}
                                />
                            ) : null}
                            {data?.logo ? (
                                <div className="w-20 md:w-32 text-center relative z-10">
                                    <div className="w-full max-w-12 md:max-w-24 max-h-10 md:max-h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                        <GatsbyImage
                                            image={getImage(
                                                data?.logo?.localFile
                                                    ?.childImageSharp ||
                                                    data?.logo
                                            )}
                                            className="w-full max-w-12 md:max-w-24 max-h-10 md:max-h-20"
                                            imgStyle={{ objectFit: 'contain' }}
                                            alt="test"
                                        />
                                    </div>
                                </div>
                            ) : null}
                            <div className="flex-1 flex md:hidden lg:flex items-center justify-end">
                                <div className="flex-1 hidden sm:flex items-center justify-end ml-8 relative transform group-hover:-translate-x-4 font-bold text-xs text-white uppercase z-10 transition-all whitespace-nowrap">
                                    Browse Products
                                </div>
                                <SVG
                                    src={interfaceArrowsButtonRight}
                                    className="w-3 h-auto ml-4 text-white relative z-10"
                                />
                            </div>
                        </Link>
                    )
                )}
                {primary?.bottom_label && primary?.bottom_link ? (
                    <Link
                        to={
                            primary?.bottom_link?.uid === 'home'
                                ? '/'
                                : primary?.bottom_link?.uid
                        }
                        className="group flex-0 md:flex-1 flex items-center h-20 pl-12 pr-8 relative overflow-hidden"
                    >
                        <span className="block w-full h-double absolute top-1/2 left-4 group-hover:left-0 transform -translate-y-1/2 -translate-x-full group-hover:translate-x-0 bg-grey z-0 transition-all duration-300 pointer-events-none" />
                        <span className="block w-32 md:w-auto md:max-w-xs relative font-bold text-xl md:text-2xl lg:text-4xl text-left md:text-center lg:text-left text-white leading-none uppercase z-10">
                            {primary?.bottom_label}
                        </span>
                        <div className="flex-1 flex md:hidden lg:flex items-center justify-end">
                            <div className="flex-1 hidden sm:flex items-center justify-end ml-8 relative transform group-hover:-translate-x-4 font-bold text-xs text-white uppercase z-10 transition-all whitespace-nowrap">
                                {primary?.bottom_small_text || 'Contact Us'}
                            </div>
                            <SVG
                                src={interfaceArrowsButtonRight}
                                className="w-3 h-auto ml-4 text-white relative z-10"
                            />
                        </div>
                    </Link>
                ) : null}
            </div>
        </div>
    ) : null
}

export default ForcesHero
