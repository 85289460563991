import * as React from 'react'
import { ContextProvider } from './src/context'
import {
    PrismicPreviewProvider,
    componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

import PageTemplate from './src/templates/page'
import ArmedForceTemplate from './src/templates/armed-force'

import './src/styles/index.scss'

import { linkResolver } from './linkResolver'

export const wrapRootElement = ({ element }) => (
    <PrismicPreviewProvider
        repositoryConfigs={[
            {
                repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
                linkResolver,
                componentResolver: componentResolverFromMap({
                    page: PageTemplate,
                    'armed-force': ArmedForceTemplate,
                }),
            },
        ]}
    >
        <ContextProvider>{element}</ContextProvider>
    </PrismicPreviewProvider>
)
