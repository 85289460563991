import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

import logo from '../../../images/global/logo.svg'

import { useMenuState } from '../../../context'

import SVG from '../../SVG'
import Link from '../../Link'

const DefaultHeader = ({ location, forceHeader = false }) => {
    const { data } = useMergePrismicPreviewData(
        useStaticQuery(graphql`
            query getHeader {
                config: prismicGlobalConfiguration {
                    _previewable
                    data {
                        header_link {
                            uid
                            url
                        }
                        header_link_text
                        main_menu {
                            label
                            link {
                                uid
                                url
                            }
                            colour
                        }
                    }
                }
            }
        `)
    )

    const [scrollTop, setScrollTop] = useState(0)
    const { menuOpen, setMenuOpen } = useMenuState(false)
    const items = data?.config?.data?.main_menu

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen)
    }

    useEffect(() => {
        const onScroll = (e) => {
            setScrollTop(e.target.documentElement.scrollTop)
        }
        window.addEventListener('scroll', onScroll)

        return () => window.removeEventListener('scroll', onScroll)
    }, [scrollTop])

    useEffect(() => {
        setMenuOpen(false)
    }, [location])

    return (
        <>
            <div
                className={`${
                    scrollTop > 300 || forceHeader
                        ? 'translate-y-0 opacity-100 visible pointer-events-auto'
                        : 'xl:-translate-y-2 xl:opacity-0 xl:invisible xl:pointer-events-none'
                } flex items-center justify-between w-full h-20 px-8 xl:pr-0 fixed top-0 left-0 transform shadow-xl h-20 text-right bg-navy z-50 transition-all`}
            >
                <div className="flex items-center justify-start w-96">
                    <Link to="/">
                        <SVG
                            src={logo}
                            className={`${
                                menuOpen ? 'opacity-0' : 'opacity-100'
                            } h-auto w-16`}
                        />
                    </Link>
                    {data?.config?.data?.header_link &&
                    data?.config?.data?.header_link_text ? (
                        <Link
                            to={
                                data?.config?.data?.header_link?.uid
                                    ? `/${data?.config?.data?.header_link?.uid}`
                                    : data?.config?.data?.header_link?.url
                            }
                            className="hidden md:block ml-4 md:ml-8 text-sm text-white"
                        >
                            {data?.config?.data?.header_link_text}
                        </Link>
                    ) : null}
                </div>

                {items?.length > 0 ? (
                    <ul
                        className={`flex-1 flex flex-col xl:flex-row items-center justify-center xl:justify-between w-screen xl:w-auto h-screen xl:h-auto xl:-ml-3 fixed xl:static top-0 xl:top-auto left-0 xl:left-auto font-bold text-sm text-white bg-navy xl:bg-transparent uppercase ${
                            menuOpen
                                ? 'opacity-100 visible'
                                : 'opacity-0 invisible'
                        } xl:opacity-100 xl:visible transition-all`}
                    >
                        {items?.map(({ link, label, colour }, i) => (
                            <li key={i} className="xl:flex-1 w-full xl:w-auto">
                                <Link
                                    to={
                                        link?.uid
                                            ? `/${
                                                  link?.uid === 'home'
                                                      ? ''
                                                      : link?.uid
                                              }`
                                            : link?.url
                                    }
                                    external={!link?.uid}
                                    className="group flex items-center justify-center w-full xl:w-auto h-20 px-8 relative overflow-hidden"
                                >
                                    <span
                                        className={`block ${
                                            colour?.toLowerCase() === '#ffffff'
                                                ? 'group-hover:text-navy'
                                                : ''
                                        } relative z-10 transition-all whitespace-nowrap`}
                                    >
                                        {label}
                                    </span>
                                    <span
                                        className={`block w-full h-full absolute left-0 transform ${
                                            location?.pathname ===
                                            `/${link?.uid}`
                                                ? 'top-0 -translate-y-0'
                                                : '-top-2 translate-y-full group-hover:top-0 group-hover:-translate-y-0'
                                        } bg-grey z-0 transition-all`}
                                        style={{
                                            backgroundColor: colour || null,
                                        }}
                                    />
                                </Link>
                            </li>
                        ))}
                    </ul>
                ) : null}

                <button
                    type="button"
                    className="flex xl:hidden items-center justify-center w-10 h-10 relative z-50 text-center text-white"
                    onClick={handleMenuToggle}
                >
                    <span className="flex flex-col items-center justify-center w-6 h-6 relative text-center">
                        <span
                            className={`block w-6 h-0.5 -mt-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                                menuOpen ? '-mt-3 opacity-0' : 'opacity-100'
                            } bg-white transition-all duration-200`}
                        />
                        <span className="block w-6 h-0.5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white transition-all duration-200" />
                        <span
                            className={`block w-6 h-0.5 mt-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                                menuOpen ? 'mt-3 opacity-0' : 'opacity-100'
                            } bg-white transition-all duration-200`}
                        />
                    </span>
                </button>
            </div>
            <span
                className={`block ${
                    forceHeader ? 'xl:block' : 'xl:hidden'
                } w-full h-20 bg-navy`}
            />
        </>
    )
}

export default DefaultHeader
