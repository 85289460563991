import React, { useState } from 'react'
import Cookies from 'js-cookie'

import Toggle from '../../../Inputs/Toggle'

const CookieSettings = ({
    handleAccept,
    handleDecline,
    initTracking,
    handleCloseAll,
}) => {
    const [trackAnalytics, setTrackAnalytics] = useState(
        Cookies.get('mod-gdpr-google-analytics') === 'true'
    )

    const handleToggle = () => {
        Cookies.set('swh-gdpr-responded', true, { expires: 365 })

        setTrackAnalytics((prevState) => {
            if (prevState) {
                Cookies.remove('mod-gdpr-google-analytics')
            } else {
                Cookies.set('mod-gdpr-google-analytics', true, {
                    expires: 365,
                })
            }

            return !prevState
        })

        initTracking()
    }

    return (
        <div className="cookie-settings w-full sm:w-96 h-full p-8 fixed top-0 left-0 bg-white shadow-2xl overflow-y-scroll z-50">
            <h3 className="my-4 font-bold text-lg text-navy leading-snug">
                This site uses cookies to store information on your computer.
            </h3>
            <div className="text-xs">
                <p>
                    Some of these cookies are essential, while others help us to
                    improve your experience by providing insights into how the
                    site is being used.
                </p>
                <p className="mt-2">
                    You can find out how to manage your preferences in relation
                    to our use of cookies at Cookie Policy
                </p>
            </div>
            <div className="flex items-center mt-6">
                <button
                    className="group inline-flex items-center justify-center w-full sm:w-auto h-10 px-4 sm:mt-0 font-bold text-xs text-white bg-greyblue hover:bg-opacity-70 uppercase transition"
                    onClick={() => handleAccept()}
                >
                    <span className="block transform group-hover:-translate-0.5 transition-all">
                        Accept
                    </span>
                </button>
                <button
                    onClick={() => handleDecline()}
                    className="group inline-flex items-center justify-center w-full sm:w-auto h-10 px-4 ml-2 sm:mt-0 font-bold text-xs text-white bg-grey hover:bg-opacity-70 uppercase transition"
                >
                    <span className="block transform group-hover:-translate-0.5 transition-all">
                        Decline
                    </span>
                </button>
            </div>
            <div className="pt-8 mt-8 border-t-4 border-grey border-opacity-10">
                <div className="mb-4 flex items-center justify-between">
                    <h4 className="font-bold text-xs uppercase">
                        Necessary Cookies
                    </h4>
                </div>
                <p className="text-xs">
                    Necessary cookies enable core functionality such as page
                    navigation and access to secure areas. The website cannot
                    function properly without these cookies, and can only be
                    disabled by changing your browser preferences.
                </p>
            </div>
            <div className="pt-8 mt-8 border-t-4 border-grey border-opacity-10">
                <div className="mb-4 flex items-center justify-between">
                    <h4 className="font-bold text-xs uppercase">
                        Analytical Cookies
                    </h4>
                    <Toggle
                        active={trackAnalytics}
                        toggleActive={() => handleToggle()}
                    />
                </div>
                <p className="text-xs">
                    Analytical cookies are non-essential cookies which help us
                    to improve our website by collecting and reporting
                    information on its usage. We use these cookies so we can
                    better understand how you engage with our website, marketing
                    campaigns and products. The information we collect is
                    anonymised.
                </p>
            </div>
            <button
                className="group inline-flex items-center justify-center w-full sm:w-auto h-10 px-4 mt-6 font-bold text-xs text-white bg-greyblue hover:bg-opacity-70 uppercase transition"
                onClick={() => handleCloseAll()}
            >
                <span className="block transform group-hover:-translate-0.5 transition-all">
                    Close
                </span>
            </button>
        </div>
    )
}

export default CookieSettings
