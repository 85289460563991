import React from 'react'

import './style.scss'

const Spinner = ({ className }) => (
    <div className="spinner">
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`spinner__svg ${className}`}
        >
            <circle
                cx="8"
                cy="8"
                r="7"
                strokeWidth="2"
                className="spinner__circle"
            />
        </svg>
    </div>
)

export default Spinner
